/*------------------  Tamaño de Iconos de boostrap ------------------*/

.wrapper:before{
  z-index: -9;
}
.sidebar-link i, a.sidebar-link i{
  color: #153d77;
}
.content{
  margin-top: 1.5rem !important;
}

.header{
  margin-bottom: 2rem;
}
.header hr{
  height: 1px;
  background-color: #ffffff;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

.table-action i{
  font-size: 1.1rem !important;
  transition: opacity 1s;
  text-align: center;
}

.bi-1{
  font-size: 1rem !important;
  transition: opacity 1s;
}

.bi-1125{
  font-size: 1.125rem !important;
  transition: opacity 1s;
}

.bi-125{
  font-size: 1.25rem !important;
  transition: opacity 1s;
}

.bi-15{
  font-size: 1.5rem !important;
  transition: opacity 1s;
}
.bi-175{
  font-size: 1.75rem !important;
  transition: opacity 1s;
}
.bi-2{
  font-size: 2rem !important;
  transition: opacity 1s;
}
.bi-25{
  font-size: 2.5rem !important;
  transition: opacity 1s;
}
.bi-3{
  font-size: 3rem !important;
  transition: opacity 1s;
}

.bi-4{
  font-size: 4rem !important;
  transition: opacity 1s;
}

.bi-5{
  font-size: 5rem !important;
  transition: opacity 1s;
}


.user-initials{
  color: #ffffff;
  background-color: #153d77 !important;
}

.text-lightgray{
  color: lightgray !important;
}

.text-gray{
  color: gray !important;
}

.text-darkgray{
  color: darkslategray !important;
}

.text-black{
  color:#000000 !important;
}

.text-white{
  color:#ffffff !important;
}


.fs-075{
  font-size: .75rem !important;
}

.fs-08{
  font-size: .8rem !important;
}

.fs-09{
  font-size: .9rem !important;
}

.fs-1{
  font-size: 1rem !important;
}

.fs-11{
  font-size: 1.1rem !important;
}

.fs-12{
  font-size: 1.2rem !important;
}

.fs-13{
  font-size: 1.3rem !important;
}

.fs-14{
  font-size: 1.4rem !important;
}

.fs-15{
  font-size: 1.5rem !important;
}

/* table tr.editable-cell:hover{
  background-color: #d4e2f7!important;
} */

/* table tr.editable-cell td input[type="text"]:disabled.form-control:hover{
  background-color: #d4e2f7!important;
} */


table tr.editable-cell td input[type="text"]:disabled.form-control{
  background-color: transparent !important;
  border: none !important;
  padding-left: 0;
}


.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: .6;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: .6;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: .6;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: .6;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: .6;
}

.placeholder-light::placeholder{
  color: #ffffff !important;
  opacity: .7 !important;
}



/* -------------- Surveys View Styles -------------------*/

.survey-header{
  background-color: #153d77 !important;
}
.survey-header header img{
  max-width: 218px;
  height: auto;
}


.survey-fs-1{
  font-size: 1rem !important;
}

.survey-fs-11{
  font-size: 1.1rem !important;
}

.survey-fs-12{
  font-size: 1.2rem !important;
}

.survey-fs-3{
  font-size: 3rem !important;
}



@media (max-width: 575.98px) {

  .survey-fs-1{
    font-size: 0.9rem !important;
  }

  .survey-fs-11{
    font-size: 1rem !important;
  }

  .survey-fs-12{
    font-size: 1rem !important;
  }

}
